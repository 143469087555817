.contact{
    /* padding: 50px 50px 50px 71px; */
    padding: 0% 15% 5% 12%;
}
textarea.email-input.textarea {
    background: #f6f6f9;
    border: none;
    width: 100%;
    height: 100px;
    border-radius: 19px;
    padding: 10px;
}