.best_selling {
background-image: url("../../images/background/popular_performer.jpg");
background-size:auto;
background-position:0 0;
background-repeat: repeat;
padding-top: 50px;
position: relative;
z-index: 9999;
}
.best_selling .section-heading h2{
	color:#fff;    
}
.event_location1
{
position: relative;
top: 0;
background-image: url("../../images/gradient.png");
height: 100%;
padding: 0;
overflow: hidden;
text-overflow: ellipsis;
background-position: bottom center;
background-repeat: no-repeat;
width: 100%;
background-size: 100% 40%;
}
.selling_top{
	position: absolute;
bottom: 0;
text-align: center;
padding: 0px;
width: 100%;
//background: linear-gradient(355deg, #080813 0%, #080813 60%, #08081300 100%);
min-height: 50px;
text-shadow: 1px 1px 1px #000;
}

.selling_top h5{
	color: #fff;
	font-weight: 500;
	font-size: 16px;
	padding-left: 5px;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
}
 
.css-1vaszmq{
width:100%;

}
.selling_top p{
	color: #fff;
font-size: 15px;
font-weight: 500;
}

  .best_selling .event {
margin-bottom: 30px;
}
 
@media screen and (min-width:2000px) {
.best_selling
{
	padding-top: 50px;
}

.selling_top h5{
	font-size:2.7em;
}
}

@media screen and (min-width:3000px) {
	.best_selling{
		background-size: auto 100% !important;	
		padding-top: 205px !important;
	}
}
.best-selling:hover{
	//border:5px solid blue;
	transition: ease-in-out .5s;
	box-shadow: 0px 0px 0px 5px #ffffff;
  }
.pop-performer {
    padding-bottom: 4% !important;
}