
.popular-performer{
  padding: 100px 0;
}

.performer .event_location h3{
	font-weight: 600;
	font-size: 24px;
}

figure.artist_img {
  width: 200px ;
  height: 200px ;
  margin: 0 auto 10px !important;
  cursor: pointer;

  img{
  border-radius: 50%;
    width: 100% ;
    height: 100% ;
    object-fit: cover;
    cursor: pointer;

  }
}







.top_user{
  position: relative;
&::after{
  content: "";
  background-image: url('../../images/icons/star1.png');
  width: 100px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  top: -50px;
  right: 0;
  left: 0;
  z-index: 0;
  margin: auto;
}
&::before{
  content: "";
  background-image: url('../../images/icons/star3.png');
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-position: right;
  background-size: 60px;
  position: absolute;
  top: -90px;
  right: 0;
  z-index: 0;
}

}




.bg_star{
  position: relative;
  z-index: 9999;
  &::after{
    
    background-image: url('../../images/icons/star3.png');
    width: 100px;
    height: 100px;
    background-repeat: no-repeat;
    background-position: left;
    background-size: 80px;
    position: absolute;
    top: -110px;
    left: 80px;
    z-index: 0;
  }
  &::before{
    background-image: url('../../images/icons/star3.png');
    width: 100px;
    height: 100px;
    background-repeat: no-repeat;
    background-position: left;
    background-size: 80px;
    position: absolute;
    bottom: -40px;
    left: -40px;
    z-index: 0;

  }
  }


 .performer .event_location{
  background-color: #203961;
  height: 50px;
  position: relative;
  bottom: 0;
  top: initial;
  padding: 10px 20px;
  width: 100%;
}

  .performer  .event {
	margin-bottom: 30px;
}
.coming-events-container {
    //max-width: 1600px;
    margin: 70px auto 0;
}

div#star3.coming_events
{
  //background-image: url('../../images/background/top_artists_bg.jpg');
  background-position: center center !important;
  background-repeat: no-repeat;
  background-size: 100% auto;
  position: relative;
  padding-top: 100px;
  margin-bottom: 100px;

} 




// .container-coming-events-popular-events {
//   padding-left: 7%;
//   padding-right: 7%;

// }
.search_details.performer-search {
  width: 20%;
}
.fa-star.upcoming-events {
  color: #0466ff;
  font-size: 30px;
  padding: 6px;
}
.artist-name {
  text-align: center;
  margin: 0px 0px 20px;
  position: relative;
z-index: 9999;
}
.top-artists {
  border-radius: 50%;
border: 5px solid transparent;
transition: 0.5s;
position: relative;
z-index: 99999;
text-align: center;
}
 

.performer {
  .perfomer-box{
   
    padding: 4%;
      padding-top: 4%;
      padding-bottom: 4%;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.artist_name {
  font-size: 23px !important;
font-weight: 600;
letter-spacing: 0px;
}
.showing_upcoming_events {
  color: #0466ff !important;
  font-size: 19px !important;
  line-height: 22px !important;
  letter-spacing: 1px !important;
}
 

.performer-search-event-image {
  max-width: 100px;
  min-width: 100px;
  border-radius: 20px;
  height: 90px;
}
.performer-search-artist-box{
  padding: 5% 7% 0 7%;
}

.performer-search-event-date {
  display: flex;
  width: 25%;
}

.performer-search-calendar-icon{
  font-size: 16px;
padding: 6px;
color: #0466ff;
}

.performer-search-calendar-icon i{
  font-weight:700;
}
.performer-search-event-name {
 
  font-size: 17px;
  font-weight: 700;
  color: #17192d;
}
.performer-search-event-location {
  color: #0466ff;
  font-size: 14px;
  font-weight: 500;
}
.check-tick-bts {
  background: #0466ff;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 21px;
  font-size: 15px;
}

.upoming-events-details {
  width: 51%;
  margin-left: 10px;
}

.performer-search-check-results {
  width: 25%;
  text-align: right;
}

.artist-name h3 {
  font-size: 17px;
font-weight: 600;
color: #17192d;
}

.top-artists img{
  min-height:150px !important;
  max-height: 150px !important;
  width: 150px !important;
  border-radius: 50%;
  border: 7px solid transparent;
}

// figure.artist_img img:hover{
//   border: 7px solid blue;
//   transition: ease-in-out .5s;
// }
.perfomer-box .artist_img img:hover {
  transition: ease-in-out 0.5s;
  box-shadow: 0px 0px 0px 5px blue;
}

 
.animated {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}


.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}


@-webkit-keyframes slideInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(500px);
    transform: translateY(500px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slideInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(500px);
    -ms-transform: translateY(500px);
    transform: translateY(500px);
  }

  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}


@keyframes img_one
{
  0%
  {
    -webkit-transform: rotate(100deg);
      -ms-transform: rotate(100deg);
        transform: rotate(100deg);
        transform: translateY(156px);
         -webkit-transform: translateY(156px);
          -moz-transform: translateY(156px);
        opacity: 0;
  }


  100%
  {
        -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
        transform: rotate(0deg);
   transform: translateY(0px);
         -webkit-transform: translateY(0px);
          -moz-transform: translateY(0px);
  }
}

@-webkit-keyframes img_one
{
  0%
  {
    -webkit-transform: rotate(100deg);
      -ms-transform: rotate(100deg);
        transform: rotate(100deg);
        transform: translateY(156px);
         -webkit-transform: translateY(156px);
          -moz-transform: translateY(156px);
        opacity: 0;
  }


  100%
  {
        -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
        transform: rotate(0deg);
   transform: translateY(0px);
         -webkit-transform: translateY(0px);
          -moz-transform: translateY(0px);
  }
}

 
@-webkit-keyframes slidetilUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slidetilUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    -ms-transform: translateY(50px);
    transform: translateY(50px);
  }

  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

 

@-webkit-keyframes slideUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(500px);
    transform: translateY(500px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(500px);
    -ms-transform: translateY(500px);
    transform: translateY(500px);
  }

  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}


@-webkit-keyframes slideInLeft {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
    transform: translateX(50px); 
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes slideInLeft {
  0% {
    -webkit-transform: translateX(50px);
    opacity: 0;
    -ms-transform: translateX(50px);
    transform: translateX(50px);
  }

  100% {
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    opacity: 1;
    transform: translateX(0);
  }
}
 
@-webkit-keyframes slideInRight {
  0% {
    -webkit-transform: translateX(-70px);
    transform: translateX(-70px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  0% {
    -webkit-transform: translateX(-70px);
    -ms-transform: translateX(-70px);
    transform: translateX(-70px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

 
@-webkit-keyframes slideOutLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px);
  }
}

@keyframes slideOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    transform: translateX(-2000px);
  }
}

 
@-webkit-keyframes slideline-1 {
  0% {
    -webkit-transform: translateX(-1500px);
    transform: translateX(-1500px);
  }

  100% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
  }
}

@keyframes slideline-1 {
  0% {
    -webkit-transform: translateX(-1500px);
    -ms-transform: translateX(-1500px);
    transform: translateX(-1500px);
  }

  100% {
    -webkit-transform: translateX(1000px);
    -ms-transform: translateX(1000px);
    transform: translateX(1000px);

  }
}
 
@-webkit-keyframes slideline-2 {
  0% {
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px);
  }

  100% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
  }
}

@keyframes slideline-2 {
  0% {
    -webkit-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    transform: translateX(2000px);
  }

  100% {
    -webkit-transform: translateX(-1000px);
    -ms-transform: translateX(-1000px);
    transform: translateX(-1000px);

  }
}
 
@-webkit-keyframes pine-spa {
  0% {
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px);
    margin-top: -20px;
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes pine-spa {
  0% {
    -webkit-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    transform: translateX(2000px);
  }

  100% {
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px);

  }
}

 
@-webkit-keyframes copyright-ani {
  0% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes copyright-ani {
  0% {
    -webkit-transform: translateY(100px);
    -ms-transform: translateY(100px);
    transform: translateY(100px);
     opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px);

  }
}
 
@-webkit-keyframes animated {
  0% {
    -webkit-transform: translateY(-70px);
    transform: translateY(-70px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1
  }
}

@keyframes animated {
  0% {
    -webkit-transform: translateY(-70px);
    -ms-transform: translateY(-70px);
    transform: translateY(-70px);
     opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;

  }
}
 
.wow
{
   animation-duration: 1s;
}
@-webkit-keyframes tada {
  0% {-webkit-transform: scale(1);} 
  10%, 20% {-webkit-transform: scale(0.9);}
  30%, 50%, 70%, 90% {-webkit-transform: scale(1.1);}
  40%, 60%, 80% {-webkit-transform: scale(1.1);}
  100% {-webkit-transform: scale(1);}
}

@-moz-keyframes tada {
  0% {-moz-transform: scale(1);}  
  10%, 20% {-moz-transform: scale(0.9);}
  30%, 50%, 70%, 90% {-moz-transform: scale(1.1);}
  40%, 60%, 80% {-moz-transform: scale(1.1) ;}
  100% {-moz-transform: scale(1);}
}

@-o-keyframes tada {
  0% {-o-transform: scale(1);}  
  10%, 20% {-o-transform: scale(0.9);}
  30%, 50%, 70%, 90% {-o-transform: scale(1.1);}
  40%, 60%, 80% {-o-transform: scale(1.1);}
  100% {-o-transform: scale(1);}
}

@keyframes tada {
  0% {transform: scale(1);} 
  10%, 20% {transform: scale(0.9) 
  }
  30%, 50%, 70%, 90% {transform: scale(1.1) }
  40%, 60%, 80% {transform: scale(1.1)}
  100% {transform: scale(1);}
}

@-webkit-keyframes spinAround {
  from {
    -webkit-transform: rotate(0deg)
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes spinAround {
  from {
    -moz-transform: rotate(0deg)
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@keyframes spinAround {
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes bob-on-hover {
  0% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  50% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
@keyframes bob-on-hover {
  0% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  50% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
@-webkit-keyframes bob-on-hover-float {
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
@keyframes bob-on-hover-float {
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
 
 
 
@keyframes floating {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(0, 15px); }
    to   { transform: translate(0, -0px); }    
}

@keyframes pulse{
  25%  {transform: scale(0.92);}
  75%  {transform: scale(1);}
}
.no-events-found {
  padding: 0 0 0 100px;
  font-size: 16px;
}

 
@keyframes circle{
  0%{
    transform:rotate(0deg)
              translate(-400px)
              rotate(0deg);
  }

  100%{
    transform:rotate(360deg)
              translate(-400px)
              rotate(-360deg);
  }
}

@keyframes circle2{
  0%{
    transform:rotate(0deg)
              translate(-450px)
              rotate(0deg);
  }

  100%{
    transform:rotate(360deg)
              translate(-450px)
              rotate(-360deg);
  }
}

.bg_img {
  position: relative;
  &::after{
    background: url("../../images/icons/flower.svg");
    width: 200px;
    height:200px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: left top;
    position: absolute;
    left: -100px;
    top: -30px;
    transform: rotate(26deg);

  }
} 

.flower_after{
  position: relative;
  &::before{
    background: url("../../images/icons/flower2.svg");
    width: 200px;
    height: 200px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-size: cover;
    background-position: right bottom;
    position: absolute;
    bottom: 0;
    right: -70px;
    transform: rotate(45deg);

  }
}
















/*media query start*/
@media(max-width:2700px){



  .top_user::after {
    top: -50px;
    
  
}




.bg_star::after {
  background-size: 60px;
  top: -130px;
  left: 180px;
}
}

@media(max-width:2500px){
  .top_user::after {
    top: -50px;
  
}
}
@media(max-width:2200px){
  .top_user::after {
    top: -40px;
}
}
@media(max-width:2000px){
  .popular-performer {
    padding: 40px 0;
}
  figure.artist_img {
    width: 150px;
    height: 150px;
}


.bg_star::after {
  background-size: 60px;
  top: -100px;
  left: 76px;
}


.bg_star::before {
  background-size: 65px;
  bottom: -10px;
  left: -30px;
}
}
@media(max-width:1600px){
  .top_user::after {
    top: -60px;
}
  .popular-performer {
    padding: 20px 0;
}


.bg_star::after {
  background-size: 60px;
  top: -80px;
  left: 100px;
}
}

@media(max-width:1300px){

}

@media(max-width:1200px){

  .bg_img::after {
    width: 130px;
    height: 130px;
    left: -30px;
    top: -10px;
}

  .flower_after::before {
    width: 130px;
    height: 130px;
    bottom: 30px;
    right: -10px;
}


  .popular-performer {
    padding: 20px 0;
}
  figure.artist_img {
    width: 120px;
    height: 120px;
}
.bg_star::after {
  background-size: 60px;
  top: -80px;
  left: 100px;
  
}

.bg_star::before {
  background-size: 50px;
  bottom: 0;
  left: 0;
} 

}



@media(max-width:992px){

  .flower_after::before {
    right: 0px;
}


  .bg_star::after {
    top: -80px;
  }



figure.artist_img {
  width: 130px;
  height: 130px;
}

.bg_star::before {
  background-size: 45px;
  bottom: 0;
    left: 0;
}


}


@media(max-width:768px){
  .flower_after::before {
    width: 80px;
    height: 90px;
    right: 0px;
}
}

@media(max-width:480px){
  .flower_after::before {
    width: 60px;
    height: 60px;
    right: 0px;
}
}






/*media query end*/












