.section-heading {
  padding-bottom: 15px;
  text-align: center;
  padding-bottom: 15px;
}

div#upcoming-events.coming_events {
  background-image: url("../../images/background/upcoming_events_bg.jpg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: 0 0 !important;
}

.container-coming-events {
  padding-left: 7%;
  padding-right: 7%;
  //max-width: 1600px;
  padding-bottom: 20px;
  margin: auto;
}
.cal-icon-upevents {
  color: #0466ff;
}
@media (min-width: 992px) {
  .container-coming-events {
    width: 100%;
    margin: auto;
  }
}
.flex {
  display: flex;
}


.event_location {
  position: relative;
  top: 189px;
  background-color: #11111194;
  height: 36px;
  padding: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.event_location h3 {
  margin-top: 0;
  font-size: 12px;
  color: #fff;
  letter-spacing: 0;
  font-weight: 400;
}

.event_location img {
  margin-right: 6px;
  margin-top: -3px;
}

.event {
  position: relative;
  height: 250px;
  transition: box-shadow 0.5s ease-in-out;
}

.container {
  max-width: 900px !important;
}




.event_detail h5 {
  color: #1d1d1d;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  margin-bottom: 0;
}

.event_detail p {
  color: #bababa;
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
}
.upcoming-container {
  padding-top: 10px;
}
.event-main {
  cursor: pointer;
}
.fa-calendar.upcoming-events {
  color: #0466ff;
  font-size: 20px;
  /* padding-top: 20px; */
  padding: 5px;
  font-weight: bold;
}
// .event:hover {
//   transform: scale(0.9);
//   }
.viewallevents {
  text-align: center;
  margin-top: 5px;
}
span.viewall {
  color: #0466ff;
  font-weight: 700;
  padding: 9px 20px 8px 12px;
  border: 2px solid #0466ff;
  border-radius: 50px;
  cursor: pointer;
  font-size: 14px;
}

.eye-icon {
  padding: 10;
}

@media screen and (min-width: 2000px) {
  .container {
    max-width: 1300px !important;
  }

  .fa-calendar.upcoming-events {
    font-size: 30px;
  }

  .viewallevents {
    margin-top: 15px;
  }

  span.viewall {
    padding: 12px 20px;
    border-radius: 80px;
    font-size: 22px;
  }

  .section-heading h2 {
    margin-top: 0;
    margin-bottom: 40px;
    font-size: 33px;
  }

  .coming_events,
  .best_selling {
    padding: 50px 0px;
    padding-top: 0;
    padding-bottom: 20px;
  }

  .event {
    border-radius: 40px !important;
    height: 280px !important;
    margin-bottom: 2em !important;
    margin-left: 15px !important;
    margin-right: 15px !important;
  }



  .selling_top h5 {
    font-size: 25px !important;
    font-weight: 500 !important;
  }

  .selling_top p {
    font-size: 22px !important;
  }

  .selling_top {
    bottom: 0 !important;
  }
}

@media only screen and (max-width: 2200px) and (min-width: 1800px) {
  .container {
    max-width: 1010px;
  }



  .event-main {
    min-height: 220px !important;
  }

  .viewallevents {
    margin-top: 5px;
  }

  .event {
    height: 200px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .fa-calendar.upcoming-events {
    font-size: 20px;
    padding: 5px;
  }

  .fa-calendar.upcoming-events {
    font-size: 25px;
  }

  .viewallevents {
    margin-top: 0;
  }

  span.viewall {
    padding: 12px 19px;
    border-radius: 80px;
    font-size: 18px;
  }

  .section-heading h2 {
    font-size: 25px;
    margin-bottom: 20px;
  }

  .selling_top h5 {
    font-size: 2em !important;
  }

  .selling_top p {
    font-size: 18px !important;
  }

  .cart-details {
    font-size: 23px !important;

  }

  .event {
    border-radius: 40px !important;
    height: 230px !important;
    margin-bottom: 0em !important;
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

}

@media screen and (min-width: 3000px) {
  .container {
    max-width: 3500px !important;
  }

  .coming_events,
  .best_selling {
    padding-bottom: 100px;
  }

  .event {
    margin-bottom: 8em !important;
    height: 800px !important;
    margin-left: 40px !important;
    margin-right: 40px !important;
    border-radius: 100px !important;
  }



  .selling_top {
    bottom: 38px !important;
  }

  .selling_top h5 {
    font-size: 5em !important;
  }
  .selling_top p {
    font-size: 4em !important;
  }

  .fa-calendar.upcoming-events {
    font-size: 80px;
    margin-bottom: 35px;
  }

  span.viewall {
    padding: 39px 62px;
    border-radius: 100px;
    font-size: 45px;
  }

  .viewallevents {
    margin-top: 100px;
  }
}

.location--hide {
  animation: slideaway 200ms;
  display: none;
}

@keyframes slideaway {
  from {
    display: block;
  }
  to {
    transform: translateY(40px);
    opacity: 0;
  }
}
.location-marker {
  color: #0466ff;
  font-size: 22px;
  padding-right: 2px;
}
.event:hover {
  transition: ease-in-out 0.5s;
  box-shadow: 0px 0px 0px 5px blue;
}

