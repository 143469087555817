#footer {
  background-color: #f5f5f5 !important;
  float: none;
  width: 100%;
  border-top: none;
}
a#profile-link {
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
  align-items: center !important;
}
a.ticket-phone-number {
  pointer-events: none;
  cursor: default;
  user-select: none;
}
.footer_fdx {
  display: flex;
  justify-content: flex-start;
  align-items: start;

  .footer_inr{
    width: 24%;
    margin: 0 auto;
  }
  li {
    cursor: pointer;
  }
}


#footer h3 {
  font-size: 28px;
  margin: 0 0 30px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 500;
  text-transform: uppercase;
}

#footer img {
  max-height: 40px;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top {
  padding: 100px 0px 50px 0px;
  position: relative;
}

#footer p {
  font-size: 13px;
  line-height: 30px;
  margin-bottom: 0;
  color: #444;
  margin-top: 0px;
  font-weight: 500;
  padding-right: 0;
}

#footer ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 7px;
}

#footer ul li {
  margin-right: 2px;
}

#footer ul i {
  margin-right: 10px;
  font-size: 16px;
  color: #0466ff;
}

#footer .social_links ul i {
  margin-right: 10px;
  border: 1px solid;
  font-size: 16px;
  color: #0466ff;
  height: 32px;
  width: 32px;
  border-radius: 30px;
  text-align: center;
  line-height: 30px;
}

.fl_X{
  display: flex;
  margin: 0 !important;
}



#footer ul a {
  color: #444;
  transition: 0.3s;
  display: flex;
  line-height: 20px;
  float: none;
  font-size: 13px;
  vertical-align: middle;
  padding-bottom: 10px;
  font-weight: 500;
  align-items: center;
  text-decoration: none;
}

#footer ul a:hover {
  text-decoration: underline;
  color: #585858;
}

#footer ul a span {
  color: #585858;
}

#footer .footer-top .footer-contact p {
  font-size: 15px;
  line-height: 25px;
  margin-bottom: 0;
  color: #585858;
  margin-top: 15px;
  font-weight: 400;
  padding-right: 90px;
}

#footer .footer-top h4 {
  font-size: 15px;
  font-weight: 500;
  position: relative;
  padding-bottom: 15px;
  color: #333;
}
.footer-container {
  padding-left: 0;
  padding-right: 0;
  max-width: 980px;
  margin: auto;
  clear: both;
}

.footer-links.icos ul ~ h4 {
  margin-bottom: 0;
  padding-bottom: 3px !important;
}

.footer-logo-box{
  width: 200px;
  display: inline;
}




 
.text_text {
  border-top: 1px solid #ddd;
  text-align: center;
  padding: 15px 0;
}


.text_text p {
  font-size: 22px;
  color: #585858;
  font-weight: 500;
}
a.terms-and-condtions {
  cursor: pointer;
}
.footer-links {
  cursor: pointer;
}
.trustpilot-widget.tp-widget-wrapper {
  text-align: initial !important;
}
.trustpilot-widget.tp-widget-wrapper {
  padding: 13px 10px 0 65px;
}
.adding-space {
  padding-bottom: 3px;
}
.payments-cards {
  justify-content: center;
  /* align-items: center; */
  display: flex;
  width: 80%;
}
img.cards-accepted {
  height: 50px;
}

@media (min-width: 992px) {
  .footer-container {
    width: 100%;

    border-top: none;
  }

  #footer h3 {
    font-size: 28px;
    margin: 0 0 30px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 500;
    text-transform: uppercase;
  }

  #footer .footer-top .footer-contact {
    margin-bottom: 30px;
  }

  #footer .footer-top {
    padding: 100px 0px 50px 0px;
    position: relative;
  }

  #footer p {
    font-size: 13px;
    line-height: 30px;
    margin-bottom: 0;
    color: #444;
    margin-top: 0px;
    font-weight: 500;
    padding-right: 0;
  }

  #footer ul {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 7px;
  }

  #footer ul li {
    margin-right: 2px;
  }

  #footer ul i {
    margin-right: 10px;
    font-size: 16px;
    color: #0466ff;
  }

  #footer .social_links ul i {
    margin-right: 10px;
    border: 1px solid;
    font-size: 16px;
    color: #0466ff;
    height: 32px;
    width: 32px;
    border-radius: 30px;
    text-align: center;
    line-height: 30px;

    margin: auto;
  }

    
    #footer ul a {
    color: #444;
    transition: 0.3s;
    display: flex;
    line-height: 20px;
    float: none;
    font-size: 16px;
    vertical-align: middle;
    padding-bottom: 15px;
    font-weight: 500;
    align-items: center;
    text-decoration: none;
  }

  #footer ul a:hover {
    text-decoration: underline;
    color: #585858;
  }

  .social_links ul {
    gap: 10px;
}

  #footer ul a span {
    color: #585858;
    font-size: 16px;
    font-weight: 500;
  }

  #footer .footer-top .footer-contact p {
    font-size: 15px;
    line-height: 25px;
    margin-bottom: 0;
    color: #585858;
    margin-top: 15px;
    font-weight: 400;
    padding-right: 90px;
  }

  #footer .footer-top h4 {
    font-size: 15px;
    font-weight: 600;
    position: relative;
    padding-bottom: 10px;
    color: #333;
    margin-top: 18px;
  }
  .footer-container {
    padding-left: 0;
    padding-right: 0;
    max-width: 980px;
    margin: auto;
    clear: both;
  }

  .footer-links.icos ul ~ h4 {
    margin-bottom: 0;
    padding-bottom: 13px !important;
  }

  
  .text_text {
    border-top: 1px solid #ddd;
    text-align: center;
    padding: 15px 0;
  }

  .text_text p {
    font-size: 22px;
    color: #585858;
    font-weight: 500;
  }
  a.terms-and-condtions {
    cursor: pointer;
  }
  .footer-links {
    cursor: pointer;
  }
  .footer-container {

    width: 100%;
    margin: auto;
  }
}


@media screen and (min-width: 2000px) {
  .footer-container {
    padding-left: 0;
    padding-right: 0;
    max-width: 1600px;
  }


  #footer img {
    max-height: 60px;
  }

  #footer .footer-top h4 {
    font-size: 23px;
    font-weight: 500;
  }
  #footer .footer-top h4 .text_text {
    padding: 40px 0;
  }

  #footer p {
    font-size: 20px;
  }

  #footer ul a {
    font-size: 20px;
  }

  #footer ul i {
    font-size: 20px;
  }

  #footer .social_links ul i {
    line-height: 45px;
    height: 50px;
    width: 50px;
    font-size: 23px;
  }
}

@media only screen and (max-width: 2100px) and (min-width: 1800px) {
  .footer-container {
    max-width: 1100px;
  }


  #footer img {
    max-height: 45px;
  }

  #footer p {
    font-size: 17px;
  }

  #footer ul a {
    font-size: 16px;
  }

  #footer .footer-top h4 {
    font-size: 18px;
  }

  #footer .social_links ul i {
    line-height: 35px;
    height: 40px;
    width: 40px;
    font-size: 20px;
  }
}

@media screen and (min-width: 3000px) {
  .footer-container {
    padding-left: 0;
    padding-right: 0;
    max-width: 3500px;
    margin: auto;
    clear: both;
  }
  #footer img {
    max-height: 150px;
  }

  .text_text {
    padding: 60px 0;
  }

  #footer p {
    font-size: 40px;
  }

 
  #footer .footer-top h4 {
    font-size: 50px;
  }

  #footer ul a {
    font-size: 35px;
    line-height: 50px;
  }

  #footer .social_links ul i {
    line-height: 85px;
    height: 100px;
    width: 100px;
    border-radius: 100px;
    font-size: 52px;
  }

  .tp-widget-rating {
    font-size: 45px !important;
    margin: 15px 4px 3px !important;
  }

  .tp-widget-logo {
    width: 185px !important;
  }

  img.cards-accepted {
    height: 155px;
  }
}