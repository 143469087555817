.flex.performer-search {
    padding: 3% 11% 2% 10%;
}
// .perfomer-box {
//     cursor: pointer;
// }


.performer-pro img {
    max-width: 100px;
    min-width: 100px;
    border-radius: 50px !important;
    height: auto!important;
}

.wkend_fdx{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

