body{
  font-family: 'Poppins', sans-serif !important;
    background-color:#fff;
}
.nav-link:hover {
  color: #0466ff;
  text-decoration: none;
}


.input_search input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #fff;
}
.input_search input::-moz-placeholder { /* Firefox 19+ */
  color: #fff;
}
.input_search input::-ms-input-placeholder { /* IE 10+ */
  color: #fff;
}
.input_search input::-moz-placeholder { /* Firefox 18- */
  color: #fff;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1; /* Firefox */
}


.input_search input {
  height: 55px;
width: 100%;
background-color: #a6a6a6bf;
border: none;
border-radius: 7px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
border-top-right-radius: 8px;
border-bottom-right-radius: 8px;
border-top-right-radius: 8px;
border-bottom-right-radius: 8px;
font-size: 15px;
color: #fff !important;
padding: 0px 20px;
border-top-right-radius: 0;
border-bottom-right-radius: 0;
}

.search_button button {
  background-color: #0466ff;
  border: none;
  border-radius: 7px;
  padding: 0 24px;
  font-size: 16px;
  height: 55px;
  color: #fff;
  width: 100%;
  margin-left: -3px;
  font-weight: 400;
}

.search_section {
  background-image: url("../../images/banner1.jpg");
  height: 212px;
  background-size: 100% auto;
  display: flex;
  background-position: center bottom;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;
}

.nav-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav-logo {
  width: 300px;
}


@media(max-width:2600px){
  .nav-flex {
    max-width: 1600px;

}
}

@media(max-width:2800px){
  .nav-flex {
    max-width: 1600px;

}

}
@media(max-width:3000px){
  .nav-flex {
    max-width: 1600px;
    width: 80%;
    margin: auto;
}
}
@media (min-width: 1200px) {
  .nav-flex {
    padding: 24px 0px;
    display: flex;
  }

  .nav-logo,
  .nav-menus {
    width: 50%;
  }

  .nav-flex {
    width: 100%;
    margin: auto;
 
  }

  .logo {
    max-width: 180px;
  }

  .menu_list {
    float: right;
    display: flex;
  }

  .nav-item {
    padding: 0px 13px;
    padding-top: 7px;
  }
  
  .black .nav-link{
    color:#17192d ;
  }
   
  .nav-link {
    display: block;
    padding: 7px 14px;
    color: #fff;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: bold;
    font-family: 'Poppins', sans-serif !important;
  }

  .active_nav {
    border-bottom: none;
    color: #0367fa !important;
  }



  .search_section .container {
    padding-top: 120px;
    padding-bottom: 70px;
  }

  .search_box {
    padding: 0;
    width: 900px;
    margin: auto;
    display: flex;
  }

  .input_search {
    width: 75%;
  }
 

  .login-button{
    background-color: #0466ff;
    border-radius: 50px;
    padding: 7px 25px;
  }

  .login-button a{ 
    color:#fff !important
  }

  .input_search input:focus {
    outline: none;
  }

  .search_button {
    width: 20%;
    margin-left: -1%;
  }



  .search_button button i{
  padding-left: 10px;
  }
  

  .search_button button img {
    padding-left: 8px;
  }
  img.main-logo {
    width: 220px;
  }
  .nav-link.user-name {
    /* font-size: 12px; */
    color: #0466ff;
  }
  select.select-option {
    height: 70px;
    border: none;
    /* width: 98%; */
  }
  a.nav-link.active_nav.active {
    text-decoration: none;
  }
}
/**terms and conditions*/
p.t_and_c_heaindg {
  font-size: 18px;
  font-weight: 600;
}
.t_and_c_subheaindg {
  font-size: 16px;
  font-weight: 600;
}
select.select-option {
  border-radius: 0px 5px 5px 0px;
}
.main-spinner {
  width: 100%;
  align-items: center;
  justify-content: center;
}
.nav-link.user-name {
  color: #ffffff;
  cursor: pointer;
  padding-top: 8px;
  background: #0466ff;
  border-radius: 23px;
}

.header-dropdown .btn:focus{
  outline:none !important;
}

.header-dropdown .btn-success:focus
{
  box-shadow: none !important;
}

.dropdown-item:focus, .dropdown-item:hover {
  color: #1e2125;
  background-color: transparent;
  text-decoration: none;
}

.header-dropdown .btn {
  font-size: 14px;
  padding: 2px; 
  font-weight:600;
  background: none;
  border: none;
  color: #0466ff;
  text-transform: capitalize;
  .btn-success:active{
    background: none !important;
    
  }

}
.header-dropdown .btn-success:hover{
  background: none !important;
  color: #0466ff;
}
.header-dropdown .btn-success:focus{
box-shadow: 0;
}
.btn-success.dropdown-toggle {
  color: #0466ff !important;
  background-color: #fff !important;
  border-color: #fff !important;
}
.header-dropdown .dropdown-menu {

  font-size: 16px !important;
  min-width: 200px;
}



.header-dropdown .dropdown-item {

  color: #0466ff !important;
font-size: 14px;
font-weight: 600;

}
.header-dropdown.dropdown {
  padding: 4px;
}

.nav-link {
  color: #17192d;
}





@media screen and (min-width:2000px) {

  .header-dropdown .dropdown-item{
    font-size: 21px;
  }
  .header-dropdown .btn  {
    font-size: 21px;
  }

  

  .nav-item{
    padding-top: 8px;
  }

  img.main-logo {
    width: auto;
max-width: 35%;
  }

  .search_button button{
    font-size: 20px;
    height: 65px;
  }

  .nav-link {
    font-size:21px;
    padding: .5rem 5rem;
  }

  .login-button {
    border-radius: 100px;
padding: 8px 30px;
margin-left:20px;
  }

  .search_section{
    height:160px;
  }

}








@media only screen and (max-width:2100px) and (min-width:1800px)
{
  .nav-flex{
    max-width: 1100px;
    padding: 10px 0px;
  }

  .header-dropdown .dropdown-item {
    font-size: 19px;
  }

  .search_section{
    height: 115px;
  }

  .nav-link , .header-dropdown .btn{
    font-size: 19px;
  }
  .login-button {
    border-radius: 95px;
    padding: 5px 21px;
    margin-left: 10px;
  }

  .nav-item{
    padding-top: 5px;
  }
}


@media only screen and (max-width:1800px) and (min-width:1000px)
{
  .nav-flex {
    width: 950px;
    padding: 10px 0px;
  }

  .search_section{
    height: 100px;
  }
 
  .input_search {
    width: 70%;
  }

  .search_button{
    width: 25%;
  }

  .input_search input{
    height: 40px;
    font-size: 14px;
  }

  .search_button button{
    height: 40px;
  }
.nav-flex
  img.main-logo {
    width: 200px;
  }

  .login-button{
    padding: 5px 18px;
  }

  .nav-link{
    font-size: 14px;
  }
}


@media screen and (min-width:3000px) {
  .nav-flex {
    max-width: 2450 !important;
    padding: 40px 0px;
  }

  
  img.main-logo{
    width: auto;
    max-width: 50%;
  }

  .nav-link{
    font-size: 50px;
    padding: .5rem 5rem;
  }

  .header-dropdown .btn  , .header-dropdown .dropdown-item{
    font-size: 50px;
  }

  .header-dropdown .btn 
  {
  margin-top: -5px;
  }
  
  .header-dropdown .dropdown-menu{
    min-width: 345px;
padding: 10px 10px;
  }
  .nav-item{
    padding-top: 14px;
  
  }

  .search_section{
    height: 480px;
  }
 
  .menu_list {
    margin-top: 40px;
  }

  
  .login-button{
    border-radius: 100px;
padding: 14px 40px;
margin-left: 50px;
  }
}

@media(max-width:1200px){
  .input_search.banner_srch {
    width: 60%;
}
.search_button {
  width: 30%;
}
.menu_list {
  align-items: center;
  .nav-item.login-button {
    background: #0466ff;
    padding: 5px 20px;
    border-radius: 50px;
    a{
      color: #fff;
      font-size: 12px;
    }

  
  }
  .black a {
    color: 000;
    font-weight: bold;
    font-size: 12px;
}
a{
  font-size: 12px;
  font-weight: bold;
}


}

}





.header-dropdown .dropdown-item:hover{
  background-color:transparent !important;
}




